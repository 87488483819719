<!--
File: ConditionIndexCriteriaSingle.vue
Description: show list/pivot of Condition Index Criteria.
-->
<template>
  <md-dialog :md-active.sync="show" :md-click-outside-to-close="false">
    <md-dialog-title>{{ $t('label.edit_critera') }}
      <md-button class='md-simple md-just-icon md-round modal-default-button' @click='show = false'>
        <md-icon>clear</md-icon>
      </md-button>
    </md-dialog-title>
    <md-dialog-content>
      <div>
        <BaseDropdown :label="$t('label.indicator_type')" v-model="item.indicator_type" :items="indicator_types"
          :displayField="'val'" :valueField="'val'" />
      </div><br>
      <label><strong>{{ $t('value_from') }}: </strong> {{ item.value_from }} </label><br>
      <label><b>{{ $t('value_to') }}: </b> {{ item.value_from }} </label><br>
      <BaseDropdown :label="$t('label.score')" v-model="item.score" :items="scores" :displayField="'val'"
        :valueField="'val'" />
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-success" @click="saveItem">{{ $t('buttons.save') }}</md-button>
      <md-button class="md-primary" @click="show = false">{{ $t('buttons.close') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>
<script>
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'

  export default {
    name: 'condition-index-criteria-single',
    props: {
      show: false,
      item_id: { type: Number, default: 0 },
    },

    data() {
      return {
        item: {
          indicator_type: { type: String, default: null },
          value_from: { type: Number, default: 0 },
          value_to: { type: Number, default: 0 },
          score: { type: Number, default: 0 },
        },
        indicator_types: [
          { val: "edge_break_m2" }, { val: "repairs" }, { val: "rutting_mm" },
          { val: "potholes_no" }, { val: "iri" }, { val: "cracking" }, { val: "aadt" }
        ],
        scores: [{ val: 0 }, { val: 1 }, { val: 2 }, { val: 3 }, { val: 4 }]

      }
    },

    components: {
      BaseDropdown
    },

    methods: {
      reloadData() {
        this.$store.dispatch('LOAD_CONDITION_INDEX_CRITERIA_BY_ID', this.item_id).then((res) => {
          if (res) this.item = res
        })
      },

      saveItem() {

      }
    }
  }
</script>