<!--
File: ConditionIndexCriteria.vue
Description: show list/pivot of Condition Index Criteria.
-->
<template>
  <div>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <!--div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addCondition"> {{ $t('buttons.add') }} </md-button>
        </div-->
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <BaseDropdown :label="$t('label.criteria')" v-model="selectedFilter"
          :items="dropDownList" :displayField="'val'" :valueField="'val'"  />
      </div>
    </md-card-header>

    <md-card-content>
      <md-table class="paginated-table table-striped table-hover" :value="tableData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('label.indicator_type')" md-sort-by="indicator_type">
            {{ $t(item.indicator_type) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.score_0')">
            <div v-if="item.score_0" >
              {{ item.score_0 }}
              <!--md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="editCondition(item.score_0_id)">
                <md-icon>edit</md-icon>
              </md-button-->
            </div>
          </md-table-cell>
          <md-table-cell :md-label="$t('label.score_1')">
            {{ item.score_1 }} 
          </md-table-cell>
          <md-table-cell :md-label="$t('label.score_2')">
            {{ item.score_2 }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.score_3')">
            {{ item.score_3 }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.score_4')">
            {{ item.score_4 }} 
          </md-table-cell>
          <!--md-table-cell  :md-label="$t('tables.actions')">
            <div v-if="item.score_0_id" >
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="editCondition(item.id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell-->
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{
            $t('label.showing_from_to_of_entries', {
              from: to > 0 ? from + 1 : 0,
              to: to,
              total
            })
          }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-15">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>

  <SingleCriteria :item="selectedItem" :show="showEditDgl"/>
</div>
</template>
<script>
  import { Pagination } from '@/components'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
  import SingleCriteria from './ConditionIndexCriteriaSingle'
  import { customSortMixin } from '@/mixins/customSortMixin'
import messagesMixin from '@/mixins/messagesMixin'

  export default {
    name: 'condition-index-criteria-list',
    mixins: [customSortMixin, messagesMixin],

    data() {
      return {
        dropDownList: [],
        selectedFilter: null,
        selectedItem: null,
        showEditDgl: false,
        pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [10, 20, 50, 100],
          total: 0
        },
        currentSort: 'indicator_type',
        currentSortOrder: 'asc',
      }
    },
    components: {
      Pagination,
      BaseDropdown,
      SingleCriteria
    },

    mounted() {
      this.$store.dispatch('LOAD_HISTORY').then((history) => {
        if (history.for === 'condition_index' && history.use) {
          this.currentSort = history.data.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          this.$nextTick(() => {
            this.searchQuery = history.data.searchQuery
            this.pagination.currentPage = history.data.currentPage
          })
          this.$store.dispatch('CLEAR_HISTORY')
        } else {
        }
      })
      this.reloadData()
    },

    methods: {
      reloadData() {
        this.$store.dispatch('LOAD_CONDITION_INDEX_CRITERIA_PIVOT').then(() => {
          this.dropDownList = this.criteriaList.map(({ indicator_type }) => ({ val: indicator_type }))
          this.dropDownList.splice(0, 0, { val: '' })
        })
      },

      save_history() {
        const hist = {
          searchQuery: this.searchQuery,
          currentSort: this.currentSort,
          currentSortOrder: this.currentSortOrder,
          perPage: this.pagination.perPage,
          currentPage: this.pagination.currentPage
        }
        this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'condition_index' })
      },

      editCondition(item_id) {
        this.save_history()
        this.selectedItem = item_id
        this.showEditDgl = true
        //this.$router.push(`/condition_data/condition/upd/${id}`)
      },

      addCondition() {
        //this.save_history()
        //this.$router.push(`/condition_data/condition/add`)
      },

      handleDelete(item) {
        this.save_history()
        const distance = `${item.section}: ${item.start_distance}-${item.end_distance}`
        this.deleteConfirmation(distance).then((result) => {
          if (result.value) {
            this.$store.dispatch('DEL_CONDITION', item.data_id).then(
              () => {
                this.reloadData()
                this.deletedMessage(distance)
              },
              (err) => {
                this.errorMessage(err)
              }
            )
          }
        })
      },
    },

    computed: {
      amIAdmin() {
        return this.$store.getters.amIAdmin
      },

      criteriaList() {
        return this.$store.state.ConditionIndexCriteria.list
      },

      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },

      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) highBound = this.total
        return highBound
      },

      total() {
        return this.criteriaList.length
      },

      tableData() {
        const res = !this.selectedFilter ? this.criteriaList : this.criteriaList.filter((item) => {
          return item.indicator_type === this.selectedFilter
        })
        return res.slice(this.from, this.to)
        //return this.criteriaList.slice(this.from, this.to)
      }
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>